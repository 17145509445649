import React, { useRef } from "react";
import VariableFontCursorProximity from "../components/VariableFontCursorProximity";
import CaseStudyCard from "../components/CaseStudyCard";
import imgInstallments from "../assets/images/imgInstallments.png";
import imgDs from "../assets/images/imgDS.png";
import imgpfp from "../assets/images/imgpfp.png";
import imgCar from "../assets/images/imgcar.png";
import imgCoupons from "../assets/images/imgcoupons.png";
import VideoViewerCard from "../components/VideoViewerCard";
import kubernetes from "../assets/videos/kubernetes.mp4";
import orb from "../assets/videos/orb.mp4";
import coins from "../assets/videos/coins.mp4";
import figmaplugin from "../assets/videos/FigmaPlugin.mp4";
import goouikit from "../assets/videos/GoouiKit.mp4";
import twistcard from "../assets/videos/twistcard.mp4";
import mail from "../assets/videos/mailAnim.mp4";
import bag from "../assets/videos/AddToBagAnim.mp4";
import { IconDownload, IconExternalLink } from "@tabler/icons-react";
import { Button } from "gooui-kit";

function Home() {
  const containerRef = useRef(null);

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/oscar-rabadan-fuertes/", "_blank");
  };
  const openGithub = () => {
    window.open("https://github.com/orabadanux", "_blank");
  };
  const openFigma = () => {
    window.open("https://figma.com/@oscar_rbdn", "_blank");
  };
  const downloadFile = () => {
    const fileUrl =
      "https://drive.google.com/uc?export=download&id=1QjsDikeppGoEKx4FVERZHEiFaaCc_9zC";
    window.location.href = fileUrl;
  };

  return (
    <div className="App bg-slate-50 relative">
      <div className="flex flex-col gap-2 md:gap-0 items-center justify-center py-12 sm:py-40">
        <div className="flex flex-row gap-4 items-center">
          <div className="block md:hidden">
            <h1 className="text-4xl font-inter text-slate-950">
              Hi, I'm Oscar
            </h1>
          </div>
          <div
            ref={containerRef}
            className="relative justify-center hidden md:block"
          >
            <VariableFontCursorProximity
              label="Hi"
              fromFontVariationSettings='"wght" 300, "wdth" 100'
              toFontVariationSettings='"wght" 900, "wdth" 120'
              containerRef={containerRef}
              radius={100}
              falloff="linear"
              className="text-5xl font-inter text-slate-950"
            />
          </div>
          <div
            ref={containerRef}
            className="relative justify-center hidden md:block"
          >
            <VariableFontCursorProximity
              label="I'm"
              fromFontVariationSettings='"wght" 300, "wdth" 100'
              toFontVariationSettings='"wght" 900, "wdth" 120'
              containerRef={containerRef}
              radius={100}
              falloff="linear"
              className="text-5xl font-inter text-slate-950"
            />
          </div>
          <div
            ref={containerRef}
            className="relative justify-center hidden md:block"
          >
            <VariableFontCursorProximity
              label="Oscar"
              fromFontVariationSettings='"wght" 300, "wdth" 100'
              toFontVariationSettings='"wght" 900, "wdth" 120'
              containerRef={containerRef}
              radius={100}
              falloff="linear"
              className="text-5xl font-inter text-slate-950"
            />
          </div>
          <div className="relative h-12 sm:h-14 w-[80px] sm:w-[100px] md:w-[120px] lg:w-[160px] overflow-hidden rounded-full">
            <img
              alt=""
              src={imgpfp}
              className="h-full w-full object-cover transition-transform duration-300 ease-out hover:scale-110"
            />
          </div>

          
        </div>
        <div className="flex flex-row items-center justify-center mt-0 md:mt-4 gap-4">
          <div className="block md:hidden">
            <h1 className="text-4xl font-inter text-blue-600">
              Product Designer
            </h1>
          </div>
          <div
            ref={containerRef}
            className="relative justify-center hidden md:block"
          >
            <VariableFontCursorProximity
              label="Product"
              fromFontVariationSettings='"wght" 300, "wdth" 100'
              toFontVariationSettings='"wght" 900, "wdth" 120'
              containerRef={containerRef}
              radius={100}
              falloff="linear"
              className="text-5xl font-inter text-blue-600"
            />
          </div>
          <div
            ref={containerRef}
            className="relative justify-center hidden md:block"
          >
            <VariableFontCursorProximity
              label="Designer"
              fromFontVariationSettings='"wght" 300, "wdth" 100'
              toFontVariationSettings='"wght" 900, "wdth" 120'
              containerRef={containerRef}
              radius={100}
              falloff="linear"
              className="text-5xl font-inter text-blue-600"
            />
          </div>
        </div>
        <div className="flex font-sans flex-row xl:flex-row items-center justify-center xl:space-y-0 gap-x-3 md:gap-x-6 mt-8">
          <div className="hidden md:block">
            <Button
              label="Reach out"
              rightIcon={<IconExternalLink size={24}/>}
              onClick={() => {
                window.location.href =
                  "mailto:orabadanux@gmail.com?subject=Hello%20Oscar&body=Hi%20Oscar,%0D%0A%0D%0A";
              }}
              fontFamily="satoshimedium"
            />
          </div>
          {/* Tailwind Button for mobile screens */}
          <div className="block md:hidden">
            <button
              className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
              onClick={() => {
                window.location.href =
                  "mailto:orabadanux@gmail.com?subject=Hello%20Oscar&body=Hi%20Oscar,%0D%0A%0D%0A";
              }}
            >
              <span>Reach out</span>
              <IconExternalLink size={24} />
            </button>
          </div>
          <div className="hidden md:block">
            <Button
              label="Download CV"
              rightIcon={<IconDownload size={24} color="currentColor" />}
              onClick={downloadFile}
              fontFamily="satoshimedium"
            />
          </div>
          <div className="block md:hidden">
            <button
              className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
              onClick={downloadFile}
            >
              <span>Download CV</span>
              <IconDownload size={24} />
            </button>
          </div>
        </div>
      </div>
      {/*<div className="flex flex-row mx-auto mt-4 mb-12 sm:mb-24 gap-x-2 border py-2 w-fit pl-2 pr-3 rounded-full border-slate-200 ">
        <img alt="" src={imgProfile} className="rounded-xl h-fit" />
        <p className="font-satoshi text-base text-slate-800">Oscar Rabadán</p>
        <p className="font-satoshi text-base text-slate-800">·</p>
        <p className="font-satoshi text-base text-slate-500">
          Product Designer
        </p>
      </div>*/}

      <section
        id="gallery"
        className="w-full max-w-7xl mx-auto px-4 xl:px-0"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 gap-y-8 sm:gap-y-12">
          <CaseStudyCard
            imageUrl={imgInstallments}
            title="114% Revenue Growth with Funnels Redesign"
            readingTime="7"
            link="/case-study/installments"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
          <VideoViewerCard
            videoUrl={mail}
            title="Send email Animation"
            tool="Rive"
            chip={{
              text: "2D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
          <CaseStudyCard
            imageUrl={imgDs}
            title="Building a Design System: From Figma to Code"
            readingTime="5"
            link="/case-study/designsystem"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
          <VideoViewerCard
            videoUrl={bag}
            title="Add to Bag Animation"
            tool="Rive, Spline"
            chip={{
              text: "2D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
          <VideoViewerCard
            videoUrl={figmaplugin}
            title="Figma plugin - Find and Replace Screens"
            tool={{
              link: "https://www.figma.com/community/plugin/1426588442353231584/find-and-replace-screens",
              label: "Go to the Figma plugin",
            }}
            chip={{
              text: "CODE",
              bgColor: "bg-green-100",
              textColor: "text-green-600",
              borderColor: "border-green-300",
            }}
          />
          <VideoViewerCard
            videoUrl={coins}
            title="3D Currencies Animation"
            tool="Spline, Figma"
            chip={{
              text: "3D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
          <CaseStudyCard
            imageUrl={imgCoupons}
            title="85% Adoption Increase on Coupon Campaigns"
            readingTime="5"
            link="/case-study/coupons"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
          <VideoViewerCard
            videoUrl={twistcard}
            title="3D Twist Animation"
            tool="Spline"
            chip={{
              text: "3D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
          <VideoViewerCard
            videoUrl={goouikit}
            title="Gooui Kit - Component Library"
            tool={{
              link: "https://github.com/orabadanux/gooui-kit",
              label: "Go to the Github repository",
            }}
            chip={{
              text: "CODE",
              bgColor: "bg-green-100",
              textColor: "text-green-600",
              borderColor: "border-green-300",
            }}
          />
          <CaseStudyCard
            imageUrl={imgCar}
            title="31% Login Success Boost in Vehicle App Redesign"
            readingTime="5"
            link="/case-study/login"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
          <VideoViewerCard
            videoUrl={orb}
            title="3D AI Orb animation"
            tool="Spline, Figma"
            chip={{
              text: "3D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
          <VideoViewerCard
            videoUrl={kubernetes}
            title="3D Kubernetes animation"
            tool="Spline, Figma"
            chip={{
              text: "3D MOTION",
              bgColor: "bg-purple-100",
              textColor: "text-purple-600",
              borderColor: "border-purple-300",
            }}
          />
        </div>
      </section>
      <div id="about-me" className="w-full items-center justify-center mx-auto my-32">
        <p className="w-full flex flex-row font-inter text-4xl items-center justify-center mx-auto text-slate-950 mb-8 xl:mb-8">
          About me
        </p>
        <div className="flex flex-col xl:flex-row md:max-w-3xl xl:max-w-4xl items-center justify-center xl:items-center xl:justify-start md:mx-auto sm:mx-8 mx-4 xl:space-x-12">
          <div className=" bg-slate-950 flex flex-col rounded-xl">
            <img
              alt=""
              src={imgpfp}
              className=" mx-auto sm:max-w-md xl:max-w-sm rounded-xl xl:h-fit"
            ></img>
          </div>
          <div className="flex flex-col space-y-4 xl:space-y-3">
            <p className="mt-4 sm:mt-8  xl:mt-0 font-satoshi text-slate-800 text-base leading-relaxed">
              Hi! I'm Oscar, a Product Designer from Barcelona with a holistic
              approach to creating meaningful experiences.
            </p>
            <p className="mt-4  xl:mt-0 font-satoshi text-slate-800  text-base leading-relaxed">
              While the product manager owns the product, I see myself as the
              owner of the experience, collaborating closely with tech leads and
              product managers to design solutions that are user-focused,
              technically feasible, and aligned with business goals.
            </p>
            <p className="mt-4  xl:mt-0 font-satoshi text-slate-800  text-base leading-relaxed">
              My process is driven by research, blending UX methods and data
              analysis to craft thoughtful and effective designs.
            </p>
            <p className="mt-4 xl:mt-0 font-satoshi text-slate-800  text-base leading-relaxed">
              With coding skills, I bridge design and development by
              contributing to components, motion interactions, layouts, and
              reviewing the final frontend to align with the design vision.
            </p>
            <p className="mt-4 xl:mt-0 font-satoshi text-slate-800  text-base  leading-relaxed">
              Outside of work, I enjoy playing guitar and piano, drawing,
              watching Marvel movies, and exploring Barcelona with friends.
            </p>
          </div>
        </div>
      </div>

      <div id="contact" className="w-full">
        <div className="w-full">
          <div className="flex font-satoshimedium flex-col md:flex-row mb-24 items-center justify-center space-y-8 md:space-y-0 md:gap-x-4 lg:gap-x-8">
            <div className="hidden sm:block">
              <Button
                label="orabadanux@gmail.com"
                rightIcon={<IconExternalLink size={24} color="currentColor" />}
                onClick={() => {
                  window.location.href =
                    "mailto:orabadanux@gmail.com?subject=Hello%20Oscar&body=Hi%20Oscar,%0D%0A%0D%0A";
                }}
              />
            </div>
            {/* Tailwind Button for mobile screens */}
            <div className="block sm:hidden">
              <button
                className="flex items-center px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
                onClick={() => {
                  window.location.href =
                    "mailto:orabadanux@gmail.com?subject=Hello%20Oscar&body=Hi%20Oscar,%0D%0A%0D%0A";
                }}
              >
                <span>orabadanux@gmail.com</span>
                <IconExternalLink size={24} />
              </button>
            </div>

            {/* Repeat the same structure for other buttons */}
            <div className="hidden sm:block">
              <Button
                label="Linkedin"
                rightIcon={<IconExternalLink size={24} color="currentColor" />}
                onClick={openLinkedIn}
              />
            </div>
            <div className="block sm:hidden">
              <button
                className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
                onClick={openLinkedIn}
              >
                <span>Linkedin</span>
                <IconExternalLink size={24} />
              </button>
            </div>

            <div className="hidden sm:block">
              <Button
                label="Github"
                rightIcon={<IconExternalLink size={24} color="currentColor" />}
                onClick={openGithub}
              />
            </div>
            <div className="block sm:hidden">
              <button
                className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
                onClick={openGithub}
              >
                <span>Github</span>
                <IconExternalLink size={24} />
              </button>
            </div>

            <div className="hidden sm:block">
              <Button
                label="Figma"
                rightIcon={<IconExternalLink size={24} color="currentColor" />}
                onClick={openFigma}
              />
            </div>
            <div className="block sm:hidden">
              <button
                className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full  focus:outline-none"
                onClick={openFigma}
              >
                <span>Figma</span>
                <IconExternalLink size={24} />
              </button>
            </div>

            <div className="hidden sm:block">
              <Button
                label="Download CV"
                rightIcon={<IconDownload size={24} color="currentColor" />}
                onClick={downloadFile}
              />
            </div>
            <div className="block sm:hidden">
              <button
                className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
                onClick={downloadFile}
              >
                <span>Download CV</span>
                <IconDownload size={24} />
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center pb-4">
            <p className="font-satoshi text-slate-500 text-sm">
              © 2024 Oscar Rabadán Fuertes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
