import React from "react";
import { useNavigate } from "react-router-dom";
import imgCar2 from "../assets/images/cslogin2.png";
import { IconArrowLeft, IconDownload } from "@tabler/icons-react";
import { Button } from "gooui-kit";
import CaseStudyCard from "../components/CaseStudyCard";
import imgInstallments from "../assets/images/imgInstallments.png";
import imgCoupons from "../assets/images/imgcoupons.png";

const CaseStudyLogin = () => {
  const navigate = useNavigate();
  const loginPDF = process.env.PUBLIC_URL + "/LoginCaseStudy.pdf";

  return (
    <div className="bg-slate-50">
      <div className="max-w-2xl mx-6 sm:mx-auto">
        <div className="py-4">
          <button
            onClick={() => navigate("/")}
            className="p-2 bg-white text-slate-950 rounded-full hover:bg-slate-200"
            aria-label="Close Viewer"
          >
            <IconArrowLeft size={24} />
          </button>
        </div>
        <h1 className="text-4xl text-slate-950 font-inter leading-tight">
          Boosting Login Success by 31% in a Vehicle App with a Simplified
          Redesign
        </h1>
        <h2 className="text-lg mt-6 mb-2 text-slate-950 font-satoshibold leading-relaxed">
          🔍 What Was the Problem?
        </h2>
        <ul className="list-disc ml-8 mt-2 text-slate-800  leading-relaxed">
          <li className="py-1">
            <span className="font-satoshibold">High Drop-off Rate</span>: 64% of
            users successfully logged in.
          </li>
          <li className="py-1">
            <span className="font-satoshibold">Complex Flow</span>: The process
            was unclear, causing frequent errors.
          </li>
          <li className="py-1">
            <span className="font-satoshibold">User Frustration</span>: Many
            abandoned their login attempts due to friction.
          </li>
        </ul>
        <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
          💡 How I solved it
        </h2>
        <img
          src={imgCar2}
          alt="Login Redesign"
          className="max-w-xl mx-auto my-4 rounded-xl"
        />
        <ul className="list-disc ml-8 text-slate-800 leading-relaxed">
          <li className="py-1">
            <span className="font-satoshibold">Introducing a Step Tracker</span>
            : Giving users clear visibility on their progress to reduce
            uncertainty.
          </li>
          <li className="py-1">
            <span className="font-satoshibold">
              Reducing the Number of Steps
            </span>
            : Eliminating unnecessary fields and asking only for essential
            information.
          </li>
          <li className="py-1">
            <span className="font-satoshibold">
              Reordering Contract Signing
            </span>
            : Moving all contract agreements to the final step, right before
            confirmation, to maintain motivation and minimize drop-offs.
          </li>
        </ul>
        <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
          📈 Impact and Results
        </h2>
        <p className="py-1 ml-1 mt-3">
          <span className="font-satoshibold text-slate-800 leading-relaxed">
            ✅ Login Success Rate Increased to 83%.
          </span>
        </p>
        <p className="py-1 ml-1">
          <span className="font-satoshibold text-slate-800 leading-relaxed">
            ✅ 5% Increase in Adoption, 7% Higher Retention.
          </span>
        </p>
        <p className="py-1 ml-1">
          <span className="font-satoshibold text-slate-800 leading-relaxed">
            ✅ Increased Customer Satisfaction{" "}
          </span>
          with a smoother, friction-free experience.
        </p>

        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 rounded-2xl bg-slate-100 my-8 py-6 px-8">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg text-slate-950 font-satoshibold leading-relaxed">
              Read the Full Case Study
            </h2>
            <p className="font-satoshi text-base text-slate-800 leading-relaxed">
              Explore the full design journey, including insights and challenges
              encountered.
            </p>
          </div>
          <div className="block sm:hidden">
            <button
              className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
              onClick={() => window.open(loginPDF, "_blank")}
            >
              <span className="whitespace-nowrap">Download PDF</span>
              <IconDownload size={24} />
            </button>
          </div>
          <div className="hidden sm:block">
            <Button
              className="whitespace-nowrap"
              label="Download PDF"
              rightIcon={<IconDownload size={24} />}
              onClick={() => window.open(loginPDF, "_blank")}
              fontFamily="satoshimedium"
            />
          </div>
        </div>
        <hr className="border-t border-slate-300" />
        <h2 className="text-lg mt-8 text-slate-950 font-satoshibold leading-relaxed">
          Read other Case Studies
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 gap-y-8 mt-4 pb-24">
          <CaseStudyCard
            imageUrl={imgInstallments}
            title="114% Revenue Growth with Funnels Redesign"
            readingTime="7"
            link="/case-study/installments"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
          <CaseStudyCard
            imageUrl={imgCoupons}
            title="85% Adoption Increase on Coupon Campaigns"
            readingTime="5"
            link="/case-study/coupons"
            chip={{
              text: "Case Study",
              bgColor: "bg-blue-100",
              textColor: "text-blue-600",
              borderColor: "border-blue-300",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CaseStudyLogin;
